import _ from "lodash";
import { ImgHTMLAttributes, forwardRef } from "react";
import {
  BorderRadiusProps,
  DimensionProps,
  propsToClasses,
  restOfProps,
} from "./index_generated";
import "./layout.scss";

type ImageProps = {
  classes?: string[];
  width?: number | string;
  height?: number | string;
  round?: boolean;
  src?: string;
  alt?: string;
} & ImgHTMLAttributes<HTMLImageElement> &
  DimensionProps &
  BorderRadiusProps;

const Image = forwardRef<HTMLDivElement, ImageProps>(
  (
    {
      classes = [],
      children,
      round,
      width,
      height,
      src,
      alt,
      style,
      ...props
    }: ImageProps,
    ref
  ) => {
    return (
      <img
        className={_.compact([...propsToClasses(props), ...classes]).join(" ")}
        style={{
          ...(width
            ? _.isString(width)
              ? { width }
              : { width: `${width}px` }
            : {}),
          ...(height
            ? _.isString(height)
              ? { height }
              : { height: `${height}px` }
            : {}),
          ...style,
          zIndex: 1,
        }}
        src={src}
        alt={alt}
        {...restOfProps(props)}
      />
    );
  }
);

export default Image;
