import { HTMLAttributes } from "react";

export enum SvgIconType {
  Add = "add",
  ChevronDown = "chevron_down",
  Close = "close",
  Cursor = "cursor",
  FavoriteFilled = "favorite_filled",
  Favorite = "favorite",
  Help = "help",
  Link = "link",
  Logo = "logo",
  LogoText = "logo_text",
  MyLocation = "my_location",
  Search = "search",
  ZoomIn = "zoom_in",
  ZoomOut = "zoom_out",
  Playlist = "playlist",
  PlaylistPlaying = "playlist_playing",
  Headset = "headset",
  TriangleLeft = "triangle_left",
  Minimize = "minimize",
  TabPlaces = "tab_places",
  TabMap = "tab_map",
  TabUsers = "tab_users",
  TabPlacesInactive = "tab_places_inactive",
  TabMapInactive = "tab_map_inactive",
  TabUsersInactive = "tab_users_inactive",
  CalendarRight = "calendar_right",
  CalendarLeft = "calendar_left",
  GoogleLogin = "google_login",
  GoogleCalendar = "google_calendar",
  GoogleMeet = "google_meet",
  ArrowBack = "arrow_back",
  ArrowForward = "arrow_forward",
  YoutubeLogo = "youtube_logo",
  SpotifyLogo = "spotify_logo",
  Play = "play",
  Pause = "pause",
}

const SvgIcon = ({
  icon,
  size,
  width,
  height,
  shadow,
  ...props
}: {
  icon: SvgIconType;
  size?: number;
  width?: number;
  height?: number;
  shadow?: boolean;
} & HTMLAttributes<HTMLImageElement>) => {
  return (
    <img
      style={{
        ...(width || size ? { width: width ?? size } : {}),
        ...(height || size ? { height: height ?? size } : {}),
        ...(shadow
          ? {
              filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))",
            }
          : {}),
      }}
      src={require(`./${icon}.svg`)}
      alt={`icon_${icon.toString()}`}
      {...props}
    />
  );
};

export default SvgIcon;
