import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { initAmplitude } from "./common/amplitude";

initAmplitude();

Sentry.init({
  dsn: "https://89bd0185c92c4432abc3edc2a3c70fdc@o4504425742467072.ingest.sentry.io/4504425752166400",
  integrations: [new BrowserTracing()],
  tracesSampleRate: parseFloat(
    process.env.REACT_APP_SENTRY_SAMPLE_RATE || "1.0"
  ),
  release: process.env.REACT_APP_VERSION,
  environment: process.env.REACT_APP_ENV ?? "prod",
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

/*root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);*/
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
