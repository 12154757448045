import HFlex from "../layouts/HFlex";
import Text from "../layouts/Text";
import Button from "../layouts/Button";
import Absolute from "../layouts/Absolute";
import { LandingView, landingViews } from "../common/types";
import SvgIcon, { SvgIconType } from "../svg";
import { useIs1280, useIs430 } from "../common/useResponseive";
import VFlex from "../layouts/VFlex";
import { useEffect, useState } from "react";

function getTextWidth(text: string, font: string = "500 16px Font-500") {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  if (!context) {
    return 0;
  }

  context.font = font;

  return context.measureText(text).width + 52;
}

const calLeft = (selection: LandingView): number => {
  const titleWidth = landingViews.map((view) => getTextWidth(view.toString()));
  const left = titleWidth
    .slice(0, landingViews.indexOf(selection))
    .reduce((acc, cur) => {
      return acc + cur;
    }, 0);
  return left;
};

function Header({ landingView, setLandingView }: any) {
  const is430 = useIs430();
  const is1280 = useIs1280();
  const [blurClass, setBlurClass] = useState("");
  useEffect(() => {
    setTimeout(() => {
      setBlurClass("backdrop-blur");
    }, 1500);
  }, []);
  return (
    <>
      <VFlex
        height={is1280 ? 144 : 72}
        classes={[blurClass]}
        style={{
          left: 0,
          right: 0,
          top: 0,
          position: "fixed",
        }}
      />
      <VFlex
        width={"100%"}
        g-16
        style={{
          position: "fixed",
          top: 0,
          paddingLeft: is430 ? 16 : 60,
          paddingRight: is430 ? 16 : 60,
          maxWidth: 1440,
        }}
      >
        <HFlex width={"100%"} a-c height={72} g-8>
          <HFlex
            clickable
            hover-none
            onClick={() => {
              window.location.href = "/";
            }}
          >
            <SvgIcon icon={SvgIconType.LogoText} />
          </HFlex>
          <HFlex a-c rel style={{ marginRight: "auto", marginLeft: "auto" }}>
            {!is1280 && (
              <Absolute
                bc-l-bo
                bdr-40
                transition
                left={calLeft(landingView)}
                width={getTextWidth(landingView.toString())}
              />
            )}
            {!is1280 &&
              landingViews.map((view, i) => (
                <HFlex
                  key={i}
                  height={48}
                  a-c
                  j-c
                  bdr-40
                  clickable
                  hover-none
                  c-l-bo={landingView !== view}
                  c-l-b={landingView === view}
                  onClick={() => setLandingView(view)}
                  style={{
                    zIndex: 100001,
                    opacity: landingView === view ? 1 : 0.4,
                  }}
                  p-26-rl
                >
                  <Text t-16-500>{view}</Text>
                </HFlex>
              ))}
          </HFlex>
          <Button
            bdr-85
            type="primary"
            hover-primary
            onClick={() =>
              window.open("https://3ycgv5rij5a.typeform.com/to/ZE3TdZVn")
            }
          >
            <HFlex g-8 a-c>
              <Text>Get rubix</Text>
              <SvgIcon icon={SvgIconType.ArrowForward} size={20} />
            </HFlex>
          </Button>
        </HFlex>
        <HFlex rel>
          {is1280 && (
            <Absolute
              bc-l-bo
              bdr-40
              transition
              height={36}
              width={getTextWidth(landingView.toString())}
              style={{
                left: `calc(50% - ${
                  getTextWidth(landingView.toString()) / 2
                }px)`,
              }}
            >
              <VFlex abs></VFlex>
            </Absolute>
          )}
          <HFlex
            abs
            classes={["anim-1"]}
            style={{
              left: `calc(50% - ${calLeft(landingView)}px - ${
                getTextWidth(landingView.toString()) / 2
              }px)`,
            }}
          >
            {is1280 &&
              landingViews.map((view, i) => (
                <HFlex
                  key={i}
                  height={36}
                  a-c
                  j-c
                  bdr-40
                  clickable
                  hover-none
                  c-l-bo={landingView !== view}
                  c-l-b={landingView === view}
                  onClick={() => setLandingView(view)}
                  style={{
                    zIndex: 100001,
                    opacity: landingView === view ? 1 : 0.4,
                  }}
                  p-26-rl
                >
                  <Text t-16-500>{view}</Text>
                </HFlex>
              ))}
          </HFlex>
        </HFlex>
      </VFlex>
    </>
  );
}

export default Header;
