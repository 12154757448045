export type Und<T> = T | undefined;

export type LandingView =
  | "Overview"
  | "Features"
  | "Future"
  | "Join Us"
  | "Contact Us";

export const landingViews: LandingView[] = [
  "Overview",
  "Features",
  "Future",
  "Join Us",
  "Contact Us",
];
