import {
  track as _track,
  setUserId as _setUserId,
  setDeviceId as _setDeviceId,
  init,
} from "@amplitude/analytics-browser";

export const initAmplitude = () => {
  if (process.env.REACT_APP_AMPLITUDE) {
    init(process.env.REACT_APP_AMPLITUDE || "e99c32771c7467d84dc6d498c0b4d340");
  }
};

export const setDeviceId = (installationToken: string) => {
  _setDeviceId(installationToken);
};

export const setUserId = (userId: string) => {
  _setUserId(userId);
};

export const track = (eventType: string, eventProperties: any) => {
  _track(eventType, eventProperties);
};
