import { useRef, useLayoutEffect, useState, ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import VFlex from "../../layouts/VFlex";
import HFlex from "../../layouts/HFlex";
import Text from "../../layouts/Text";
import Image from "../../layouts/Image";
import Absolute from "../../layouts/Absolute";
import Button from "../../layouts/Button";
import { LandingView, landingViews } from "../../common/types";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Flex from "../../layouts/Flex";
import Header from "../../components/Header";
import SvgIcon, { SvgIconType } from "../../svg";
import { useIs1024, useIs1280, useIs430 } from "../../common/useResponseive";

gsap.registerPlugin(ScrollTrigger);

enum refs {
  TEXT,
  TEXT1,
  TEXT2,
  TEXT3,
  TEXT4,
  TEXT5,
  TEXT6,
}

function FeatureText({
  content,
  cRef,
  selected,
  textIndex,
  index,
}: {
  content: string;
  cRef: any;
  selected: boolean;
  textIndex: string | undefined;
  index: string;
}) {
  const is1280 = useIs1280();
  const is1024 = useIs1024();

  var element = <></>;

  if (is1024) {
    element = (
      <VFlex width={"100%"} p-16-rl>
        <Text t-28-700 c-l-bo m-24-b wrap>
          {content}
        </Text>
        <Image width={"100%"} src={`/landing/landing-${index}.png`} />
      </VFlex>
    );
  } else {
    element = (
      <HFlex ref={cRef} width={is1280 ? 904 : 1040} a-c rel>
        <Text
          t-36-700
          style={{ fontSize: is1280 ? 32 : 36 }}
          c-l-bo={selected}
          c-l-bvo={!selected}
          wrap
          m-20-b
          width={318}
        >
          {content}
        </Text>
        {!textIndex &&
          (index === refs.TEXT1.toString() ||
            index === refs.TEXT6.toString()) && (
            <VFlex
              abs
              width={is1280 ? 444 : 512}
              height={is1280 ? 444 : 512}
              bc-l-s
              bdr-16
              style={{
                right: 0,
                transform:
                  index === refs.TEXT1.toString()
                    ? "translateY(-115px)"
                    : "translateY(+115px)",
              }}
            />
          )}
      </HFlex>
    );
  }

  return <VFlex ref={cRef}>{element}</VFlex>;
}

const top = "50%";
function Landing() {
  const is430 = useIs430();
  const is1280 = useIs1280();
  const is1024 = useIs1024();
  const navigate = useNavigate();
  const [landingView, setLandingView] = useState<LandingView>("Overview");
  const gsapRef = useRef<{ [key: string]: null | HTMLDivElement }>({});
  const [textIndex, setTextIndex] = useState<string | undefined>();
  type ScrolltriggerOption = {
    ref: any;
    id?: string;
    duration?: number;
  };

  useLayoutEffect(() => {
    function customScrollTrigger(ref: any, id?: string) {
      return ScrollTrigger.create({
        trigger: ref,
        id: id,
        start: `top-=55px ${top}`,
        end: `bottom+=55px ${top}`,
        onEnter: () => {
          setTextIndex(() => id);
        },
        onLeave: () => {
          setTextIndex((prev) => (prev === id ? undefined : prev));
        },
        onEnterBack: () => {
          setTextIndex(() => id);
        },
        onLeaveBack: () => {
          setTextIndex((prev) => (prev === id ? undefined : prev));
        },
      });
    }
    function customGsapFrom({ ref, id }: ScrolltriggerOption): any {
      customScrollTrigger(ref, id);
    }

    customGsapFrom({ ref: gsapRef.current[refs.TEXT], duration: 5 });
    customGsapFrom({
      ref: gsapRef.current[refs.TEXT1],
      id: refs.TEXT1.toString(),
    });
    customGsapFrom({
      ref: gsapRef.current[refs.TEXT2],
      id: refs.TEXT2.toString(),
    });
    customGsapFrom({
      ref: gsapRef.current[refs.TEXT3],
      id: refs.TEXT3.toString(),
    });
    customGsapFrom({
      ref: gsapRef.current[refs.TEXT4],
      id: refs.TEXT4.toString(),
    });
    customGsapFrom({
      ref: gsapRef.current[refs.TEXT5],
      id: refs.TEXT5.toString(),
    });
    customGsapFrom({
      ref: gsapRef.current[refs.TEXT6],
      id: refs.TEXT6.toString(),
    });
    landingViews.forEach((view) => {
      ScrollTrigger.create({
        trigger: gsapRef.current[view],
        start: `top 50%`,
        end: `bottom 50%`,
        preventOverlaps: true,
        id: view.toString(),
        onEnter: () => {
          console.log(view);
          setLandingView(view);
        },
        onEnterBack: () => {
          console.log(view);
          setLandingView(view);
        },
      });
    });
  }, []);

  interface Position {
    position: string;
    initial: ReactElement;
  }

  const positions: Position[] = [
    {
      position: "Product manager",
      initial: (
        <VFlex bdr-32 c-c bc-a-ab width={is1024 ? 58 : 32} height={32}>
          <Text t-12-700-wt>PM</Text>
        </VFlex>
      ),
    },
    {
      position: "Content strategist",
      initial: (
        <VFlex bdr-32 c-c bc-a-ay width={is1024 ? 58 : 32} height={32}>
          <Text t-12-700-bk>CS</Text>
        </VFlex>
      ),
    },
    {
      position: "Community manager",
      initial: (
        <VFlex bdr-32 c-c bc-a-ar width={is1024 ? 58 : 32} height={32}>
          <Text t-12-700-wt>CM</Text>
        </VFlex>
      ),
    },
    {
      position: "Business developer",
      initial: (
        <VFlex bdr-32 c-c bc-a-at width={is1024 ? 58 : 32} height={32}>
          <Text t-12-700-wt>BD</Text>
        </VFlex>
      ),
    },
    {
      position: "Web3 developer",
      initial: (
        <VFlex bdr-32 c-c bc-a-ag width={is1024 ? 58 : 32} height={32}>
          <Text t-12-700-wt>WD</Text>
        </VFlex>
      ),
    },
    {
      position: "Product designer",
      initial: (
        <VFlex bdr-32 c-c bc-a-ap width={is1024 ? 58 : 32} height={32}>
          <Text t-12-700-wt>PD</Text>
        </VFlex>
      ),
    },
  ];

  const openingField = (initial: ReactElement, title: string, idx: number) =>
    is1024 ? (
      <VFlex key={idx} c-c g-16 bdr-16 p-16-tb m-16-b p-16-rl bc-l-s>
        {initial}
        <Text t-20-700-l-so>{title}</Text>
        <Text
          t-14-700-l-svo
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          Location: Work from anywhere 🌴
        </Text>
        <Button
          type="tertiary"
          c-c
          caption="Click to apply"
          clickable
          hover
          onClick={(e) => {
            window.location.href = `mailto:hr@rubix.social?subject=Apply for ${title} role at Rubix`;
            e.preventDefault();
          }}
        />
      </VFlex>
    ) : (
      <HFlex
        key={idx}
        c-c
        g-16
        bdr-16
        p-8-tb
        m-16-b
        p-16-rl
        bc-l-s
        style={{ height: 80, flexWrap: "wrap" }}
      >
        {initial}
        <Text width={200} t-20-700-l-so>
          {title}
        </Text>
        <Text
          t-14-700-l-svo
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          Location: Work from anywhere 🌴
        </Text>
        <Button
          type="tertiary"
          c-c
          caption="Click to apply"
          clickable
          hover
          onClick={(e) => {
            window.location.href = `mailto:hr@rubix.social?subject=Apply for ${title} role at Rubix`;
            e.preventDefault();
          }}
        />
      </HFlex>
    );
  return (
    <VFlex f-1 bc-wt ovf-s-d height={"100%"}>
      <VFlex classes={["noise-container"]}>
        <VFlex abs classes={["noise"]} />
      </VFlex>
      <Absolute bottom={"auto"} style={{ zIndex: 100001 }}>
        <HFlex c-c>
          <Header
            landingView={landingView}
            setLandingView={(landingView: LandingView) => {
              setLandingView(landingView);
              switch (landingView) {
                case "Overview":
                  gsapRef.current["Overview"]?.scrollIntoView();
                  break;
                case "Features":
                  gsapRef.current["Features"]?.scrollIntoView();
                  break;
                case "Join Us":
                  gsapRef.current["Join Us"]?.scrollIntoView();
                  break;
                case "Future":
                  gsapRef.current["Future"]?.scrollIntoView();
                  break;
                case "Contact Us":
                  gsapRef.current["Contact Us"]?.scrollIntoView();
                  break;
              }
            }}
          />
        </HFlex>
      </Absolute>
      {!is1024 && textIndex && (
        <div
          style={{
            position: "fixed",
            top: `calc(${top} - 252px)`,
            left: "calc(50% + 8px)",
            zIndex: 1000,
          }}
        >
          <Image
            width={is1280 ? 444 : 512}
            height={is1280 ? 444 : 512}
            src={`/landing/landing-${textIndex}.png`}
          />
        </div>
      )}
      <VFlex
        c-c
        g-16
        width={"100%"}
        height={"100vh"}
        sized
        rel
        ref={(el) => {
          gsapRef.current["Overview"] = el;
        }}
      >
        <Absolute>
          <Image width={"100%"} height={"100%"} src="/Hero.png" />
        </Absolute>
        <VFlex
          c-c
          g-16
          style={{
            zIndex: 1000,
            marginRight: 60,
            marginLeft: 60,
          }}
          ref={(e) => {
            gsapRef.current[refs.TEXT] = e;
          }}
        >
          <HFlex rel>
            <Text
              t-64-700-l-bo
              wrap
              style={{
                fontSize: is430 ? 36 : is1024 ? 40 : is1280 ? 64 : 80,
                lineHeight: "110%",
                textAlign: "center",
              }}
            >
              Enter a state of{" "}
              <Text classes={["text-effect"]}>
                <Text>flow</Text>
              </Text>
              {".\nTogether."}
            </Text>
            <Text
              abs
              t-64-700-l-bo
              wrap
              style={{
                fontSize: is430 ? 36 : is1024 ? 40 : is1280 ? 64 : 80,
                lineHeight: "110%",
                textAlign: "center",
              }}
            >
              Enter a state of{" "}
              <Text classes={["text-effect-2"]}>
                <Text>flow</Text>
              </Text>
              {".\nTogether."}
            </Text>
          </HFlex>
        </VFlex>
      </VFlex>
      <HFlex
        c-c
        g-16
        style={{
          paddingTop: is1024 ? 60 : 480,
          marginBottom: is1024 ? 120 : 880,
        }}
        sized
        ref={(el) => {
          gsapRef.current["Features"] = el;
        }}
      >
        <VFlex f-1 c-c style={{ gap: 100 }}>
          <FeatureText
            cRef={(e: any) => {
              gsapRef.current[refs.TEXT1] = e;
            }}
            selected={textIndex === refs.TEXT1.toString()}
            index={refs.TEXT1.toString()}
            textIndex={textIndex}
            content="Daily routine promotes healthy work-life harmony"
          />
          <FeatureText
            cRef={(e: any) => {
              gsapRef.current[refs.TEXT2] = e;
            }}
            selected={textIndex === refs.TEXT2.toString()}
            index={refs.TEXT2.toString()}
            textIndex={textIndex}
            content="Strike your todos and get things done"
          />
          <FeatureText
            cRef={(e: any) => {
              gsapRef.current[refs.TEXT3] = e;
            }}
            selected={textIndex === refs.TEXT3.toString()}
            index={refs.TEXT3.toString()}
            textIndex={textIndex}
            content="Your running mates to the next milestones"
          />
          <FeatureText
            cRef={(e: any) => {
              gsapRef.current[refs.TEXT4] = e;
            }}
            selected={textIndex === refs.TEXT4.toString()}
            index={refs.TEXT4.toString()}
            textIndex={textIndex}
            content="Enter a state of group flow with your team"
          />
          <FeatureText
            cRef={(e: any) => {
              gsapRef.current[refs.TEXT5] = e;
            }}
            selected={textIndex === refs.TEXT5.toString()}
            index={refs.TEXT5.toString()}
            textIndex={textIndex}
            content="Give kudos to your team to celebrate flows together"
          />
          <FeatureText
            cRef={(e: any) => {
              gsapRef.current[refs.TEXT6] = e;
            }}
            selected={textIndex === refs.TEXT6.toString()}
            index={refs.TEXT6.toString()}
            textIndex={textIndex}
            content="Track your flow sessions and build up karma"
          />
        </VFlex>
      </HFlex>

      <VFlex c-c g-16 sized>
        <Text
          t-48-700-l-bo
          wrap
          style={{ fontSize: is430 ? 32 : is1024 ? 36 : is1280 ? 40 : 48 }}
        >
          Try it for free now.
        </Text>
        <Text
          t-48-700-l-bvo
          style={{
            fontSize: is430 ? 32 : is1024 ? 36 : is1280 ? 40 : 48,
            marginBottom: 40,
          }}
          wrap
        >
          Available on iOS.
        </Text>
        <Button
          bdr-85
          type="primary"
          hover-primary
          onClick={() =>
            window.open("https://3ycgv5rij5a.typeform.com/to/ZE3TdZVn")
          }
        >
          <HFlex g-8 a-c>
            <Text>Get rubix</Text>
            <SvgIcon icon={SvgIconType.ArrowForward} size={20} />
          </HFlex>
        </Button>
        <Flex f-1 c-c style={{ marginTop: 60 }} p-16-rl>
          <Image
            width={is1024 ? "100%" : is1280 ? 904 : 1040}
            src={`/landing/landing-section-2.png`}
          />
        </Flex>
        <Text
          t-16-500
          c-l-bvo
          m-24-t
          wrap
          p-16-rl
          style={{ textAlign: "center" }}
        >
          Stay tune for upcoming Android releases 🤖
        </Text>
      </VFlex>

      <VFlex
        g-16
        style={{ paddingTop: 240 }}
        sized
        ref={(el) => {
          gsapRef.current["Future"] = el;
        }}
      >
        <VFlex a-c width={"100%"} p-32-rl>
          <Text
            c-c
            t-48-700-l-bo
            wrap
            style={{ fontSize: is430 ? 32 : is1024 ? 36 : is1280 ? 40 : 48 }}
          >
            Rubix + Web3
          </Text>
          <Text
            c-c
            t-48-700-l-bvo
            wrap
            style={{
              fontSize: is430 ? 32 : is1024 ? 36 : is1280 ? 40 : 48,
              textAlign: "center",
            }}
          >
            There are more coming soon.
          </Text>
        </VFlex>
        <HFlex
          f-1
          c-c
          g-16
          p-16-rl
          height={is1024 ? undefined : 249}
          style={{ marginTop: 60, flexWrap: "wrap" }}
        >
          <Image
            bdr-24
            width={is1024 ? "100%" : is1280 ? 329 : 380}
            src={`/landing/more_1.png`}
          />
          <Image
            bdr-24
            width={is1024 ? "100%" : is1280 ? 559 : 644}
            src={`/landing/more_2.png`}
          />
        </HFlex>
        <HFlex
          f-1
          c-c
          g-16
          p-16-rl
          height={is1024 ? undefined : 249}
          style={{ flexWrap: "wrap" }}
        >
          <Image
            bdr-24
            width={is1024 ? "100%" : is1280 ? 559 : 644}
            src={`/landing/more_3.png`}
          />
          <Image
            bdr-24
            width={is1024 ? "100%" : is1280 ? 329 : 380}
            src={`/landing/more_4.png`}
          />
        </HFlex>
        <Text
          c-c
          t-16-500
          c-l-bvo
          style={{ marginTop: 40, textAlign: "center" }}
          wrap
          p-16-rl
        >
          We’re making something very interesting for the future... 🤫
        </Text>
      </VFlex>

      <VFlex
        c-c
        g-16
        style={{ paddingTop: 240 }}
        sized
        ref={(el) => {
          gsapRef.current["Join Us"] = el;
        }}
      >
        <VFlex a-c width={"100%"} p-32-rl>
          <Text
            c-c
            t-48-700-l-bo
            wrap
            style={{
              fontSize: is430 ? 32 : is1024 ? 36 : is1280 ? 40 : 48,
              textAlign: "center",
            }}
          >
            Jump into the future now.
          </Text>
          <Text
            c-c
            t-48-700-l-bvo
            wrap
            style={{
              fontSize: is430 ? 32 : is1024 ? 36 : is1280 ? 40 : 48,
              textAlign: "center",
            }}
          >
            We want you to join us.
          </Text>
        </VFlex>
        <VFlex height={28} />
        <VFlex c-c p-16-rl width={is1024 ? "100%" : undefined}>
          <Text t-16-500-l-bvo>Current openings:</Text>
          <VFlex height={20} />
          <VFlex f-1 width={is1024 ? "100%" : is1280 ? 904 : 1040}>
            {positions.map((p, idx) => {
              return openingField(p.initial, p.position, idx);
            })}
          </VFlex>
        </VFlex>
      </VFlex>

      <VFlex
        c-c
        g-16
        style={{ paddingTop: 240, paddingBottom: 240 }}
        sized
        ref={(el) => {
          gsapRef.current["Contact Us"] = el;
        }}
      >
        <VFlex a-c width={"100%"} p-32-rl>
          <Text
            c-c
            t-48-700-l-bo
            wrap
            style={{
              fontSize: is430 ? 32 : is1024 ? 36 : is1280 ? 40 : 48,
              textAlign: "center",
            }}
          >
            Have questions or inquiries?
          </Text>
          <Text
            c-c
            t-48-700-l-bvo
            wrap
            style={{
              fontSize: is430 ? 32 : is1024 ? 36 : is1280 ? 40 : 48,
              textAlign: "center",
            }}
          >
            Reach out and we’ll get back to you.
          </Text>
        </VFlex>
        <Button
          bdr-85
          m-32-t
          type="secondary"
          caption="Reach out"
          clickable
          hover
          onClick={(e) => {
            window.location.href = `mailto:support@rubix.social?subject=Questions about rubix`;
            e.preventDefault();
          }}
        />
      </VFlex>

      <VFlex
        bc-l-b2
        c-c={!is1024}
        p-16-rl
        style={{ paddingTop: 60, paddingBottom: 60 }}
      >
        <VFlex g-16>
          <Text c-l-bo t-14-700-l-so style={{ fontWeight: 900 }}>
            rubix
          </Text>
          {is1024 ? (
            <VFlex g-8 g-16 width={is1024 ? "100%" : 380}>
              <Text t-16-500-l-svo style={{ whiteSpace: "break-spaces" }}>
                We’re a diverse remote working company. Our mission is to
                empower people to do more with less and help people focus on
                what really matters.
              </Text>
              <VFlex f-1 />
              <VFlex>
                <Text
                  t-16-500-l-svo
                  onClick={() => {
                    navigate("/privacy");
                  }}
                >
                  Privacy policy
                </Text>
                <VFlex f-1 />
                <Text
                  t-16-500-l-svo
                  onClick={() => {
                    navigate("/termsofservice");
                  }}
                >
                  Terms of service
                </Text>
              </VFlex>
              <VFlex f-1 />
              <VFlex>
                <Text t-16-500-l-svo>Rubix 2023</Text>
                <VFlex f-1 />
                <Text t-16-500-l-svo>Made in California 🌴</Text>
              </VFlex>
            </VFlex>
          ) : (
            <HFlex g-8 width={1040}>
              <Text
                width={380}
                t-16-500-l-svo
                style={{ whiteSpace: "break-spaces" }}
              >
                We’re a diverse remote working company. Our mission is to
                empower people to do more with less and help people focus on
                what really matters.
              </Text>
              <VFlex f-1 />
              <VFlex>
                <Text
                  t-16-500-l-svo
                  onClick={() => {
                    navigate("/privacy");
                  }}
                >
                  Privacy policy
                </Text>
                <VFlex f-1 />
                <Text
                  t-16-500-l-svo
                  onClick={() => {
                    navigate("/termsofservice");
                  }}
                >
                  Terms of service
                </Text>
              </VFlex>
              <VFlex f-1 />
              <VFlex>
                <Text t-16-500-l-svo>Rubix 2023</Text>
                <VFlex f-1 />
                <Text t-16-500-l-svo>Made in California 🌴</Text>
              </VFlex>
            </HFlex>
          )}
        </VFlex>
      </VFlex>

      <VFlex
        abs
        width={"100vw"}
        height={"100vh"}
        style={{ zIndex: 10000000 }}
        ovf-h
        bc-wt
        classes={["landing-logo-3"]}
      >
        <VFlex abs style={{ top: "50vh", left: "50vw" }}>
          <VFlex style={{ transform: "translate(-50%, -50%)" }}>
            <VFlex classes={["landing-logo-2"]}>
              <Image
                width={is430 ? 80 : is1024 ? 100 : is1280 ? 120 : 160}
                height={is430 ? 80 : is1024 ? 100 : is1280 ? 120 : 160}
                src={`landing_logo.png`}
              />
            </VFlex>
          </VFlex>
        </VFlex>
        <Absolute style={{ top: "100vh" }} bc-wt classes={["landing-logo"]} />
      </VFlex>
    </VFlex>
  );
}

export default Landing;
