import _ from "lodash";
import { forwardRef, HTMLAttributes } from "react";
import {
  MiscellaneousProps,
  propsToClasses,
  restOfProps,
} from "./index_generated";
import "./layout.scss";

type AbsoluteProps = {
  classes?: string[];
  top?: number | string;
  left?: number | string;
  right?: number | string;
  bottom?: number | string;
  width?: number | string;
  height?: number | string;
} & HTMLAttributes<HTMLDivElement> &
  MiscellaneousProps;

const Absolute = forwardRef<HTMLDivElement, AbsoluteProps>(
  (
    {
      classes = [],
      children,
      top = 0,
      left = 0,
      right = 0,
      bottom = 0,
      width,
      height,
      style,
      ...props
    }: AbsoluteProps,
    ref
  ) => {
    return (
      <div
        ref={ref}
        className={_.compact([...propsToClasses(props), ...classes]).join(" ")}
        style={{
          position: "absolute",
          ...(top !== "auto" && !_.isNil(top) ? { top: `${top}px` } : {}),
          ...(left !== "auto" && !_.isNil(left) ? { left: `${left}px` } : {}),
          ...(right !== "auto" && !_.isNil(right)
            ? { right: `${right}px` }
            : {}),
          ...(bottom !== "auto" && !_.isNil(bottom)
            ? { bottom: `${bottom}px` }
            : {}),
          ...style,
          ...(width
            ? _.isString(width)
              ? { width }
              : { width: `${width}px` }
            : {}),
          ...(height
            ? _.isString(height)
              ? { height }
              : { height: `${height}px` }
            : {}),
        }}
        {...restOfProps(props)}
      >
        {children}
      </div>
    );
  }
);

export default Absolute;
