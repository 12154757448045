import "./App.scss";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { useEffect, useState } from "react";
import Landing from "./scenes/Landing/Landing";
import { Toaster } from "react-hot-toast";
import Privacy from "./scenes/Privacy";
import TermsOfService from "./scenes/TermsOfService";

function To() {
  const { search } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const boardId = new URLSearchParams(search).get("boardId");
    navigate(`/${boardId}`, { replace: true });
  }, [search, navigate]);
  return <div className="bc-pp fullscreen" />;
}

function App() {
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    (async () => {
      await Promise.all([
        document.fonts.load("12px Font-500"),
        document.fonts.load("12px Font-500-Number"),
      ]);
      setIsReady(true);
    })();
  }, []);
  if (!isReady) {
    return null;
  }
  return (
    <>
      <div className="app">
        <RecoilRoot>
          <Routes>
            <Route path="/to" element={<To />} />
            <Route path="" element={<Landing />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/termsofservice" element={<TermsOfService />} />
          </Routes>
        </RecoilRoot>
      </div>
      <Toaster
        gutter={16}
        containerStyle={{ zIndex: 100000, bottom: 32 }}
        toastOptions={{
          style: { maxWidth: "unset" },
          blank: {
            position: "bottom-center",
            className: "bc-pt c-pp p-16-rl",
            style: { height: 48, borderRadius: 24 },
          },
          loading: {
            position: "bottom-center",
            className: "bc-pt c-pp p-16-rl",
            style: { height: 48, borderRadius: 24 },
          },
          success: {
            position: "bottom-center",
            className: "bc-pt c-pp p-16-rl",
            style: { height: 48, borderRadius: 24 },
          },
          error: {
            position: "bottom-center",
            className: "bc-pt c-pp p-16-rl",
            style: { height: 48, borderRadius: 24 },
          },
        }}
      />
      <div id="popup-root" />
    </>
  );
}

export default App;
