import _ from "lodash";
import { forwardRef, HTMLAttributes } from "react";
import { BorderProps, ColorProps, FlexAlignItemsProps, FlexJustifyContentProps, FlexProps, GapProps, MiscellaneousProps, propsToClasses, restOfProps } from "./index_generated";
import "./layout.scss";

type Props = {
  classes?: string[];
  width?: number | string;
  height?: number | string;
} & HTMLAttributes<HTMLDivElement> &
  FlexProps &
  FlexAlignItemsProps &
  FlexJustifyContentProps &
  ColorProps &
  GapProps &
  BorderProps &
  MiscellaneousProps;

const VFlex = forwardRef<HTMLDivElement, Props>(({ classes = [], children, width, height, style, ...props }: Props, ref) => {
  return (
    <div
      ref={ref}
      className={_.compact(["f-v", ...propsToClasses(props), ...classes]).join(" ")}
      style={{
        ...style,
        ...(width ? (_.isString(width) ? { width } : { width: `${width}px` }) : {}),
        ...(height ? (_.isString(height) ? { height } : { height: `${height}px` }) : {}),
      }}
      {...restOfProps(props)}
    >
      {children}
    </div>
  );
});

export default VFlex;
