import { useMediaQuery } from "react-responsive";

export function useIs1280() {
  return useMediaQuery({ query: "(max-width: 1280px)" });
}

export function useIs1024() {
  return useMediaQuery({ query: "(max-width: 1024px)" });
}

export function useIs834() {
  return useMediaQuery({ query: "(max-width: 834px)" });
}

export function useIs430() {
  return useMediaQuery({ query: "(max-width: 430px)" });
}
